@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.cart-mobile__items {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  @include mainContentStyles;

  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }
}
